import * as React from "react"
import Layout from "../components/layout"
import GalleryJardin from "../components/GalleryJardin"

const GalleryPageJardin = () => (
  <Layout>
    <GalleryJardin />
  </Layout>
)

export default GalleryPageJardin